.list-group-item.active {
    z-index: 2;
    border: 0;
    background: #3459e60f !important;
    background-color: #fff;
    border-left: 2px solid #3459e6;
}
.fc-daygrid-day-frame{
    background: #fff;
}
a.fc-event {
    background: #fff;
    border: 0px;
}
.badge{
    white-space: initial;
}
.fc-daygrid-event {
    white-space: nowrap;   /* Ensures the text doesn't wrap to the next line */
    overflow: hidden;      /* Ensures that long content is hidden */
    text-overflow: ellipsis; /* Adds '...' when text is too long */
    max-width: 100%;       /* Keeps the width within the boundaries of the calendar cell */
  }
  
  .fc .fc-daygrid-event .fc-event-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .fc-event-title {
    font-size: 0.9rem; /* Adjust the font size to fit more text */
  }
  