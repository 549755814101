@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  transition: opacity 0.5s ease-out;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: Montserrat, sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url("./fonts/la-brands-400.eot");
  src: url("./fonts/la-brands-400.eot?#iefix") format("embedded-opentype"), url("./fonts/la-brands-400.woff2") format("woff2"), url("./fonts/la-brands-400.woff") format("woff"), url("./fonts/la-brands-400.ttf") format("truetype"), url("./fonts/la-brands-400.svg#lineawesome") format("svg");

  margin: 0;
  padding: 0;
  background: #F1F5F9;
  z-index: '-1';
}
a {
  color: #384BD3 !important;
  text-decoration: none;
}
h1, h2, h3, h4, h5, h6 {
  -webkit-font-smoothing: antialiased;
}
h2{
  font-weight: 700;
  line-height: 45px;
}
.cursor {
  cursor: pointer;
}
.autoHeight {
  height: auto;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #888; 
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.MuiBox-root {
  padding: 0px !important;
}
.sidebar > .MuiPaper-root.MuiPaper-elevation, header.MuiPaper-root{
  margin-top: 0px !important;
}


/* min-width: 768px */
@media (min-width: 768px) {
  .content {
    padding-left: 3rem;;
  }
}
#root {
  overflow: scroll;
}
.pad-side{
  padding-left: 5rem;
}
@media only screen and (max-width: 600px) {
  .pad-side{
    padding-left: 1rem;
  }
}
a.markerLink {
  color: #fff !important;
}