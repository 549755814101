p.fs-6.mt-2.border-bottom {
    height: 4%;
    font-weight: 500;
}
.del-btn{
    border: none;
    padding-left: 4rem;
    background-color: none;
    border: 0px;
}
.uploading-outer{
    background-color: white;
    border: 1.5px dashed #57a7c1;
}
.uploadButton-input{
    visibility: hidden;
}
.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  
  .grid-container .grid-item input {
    width: 50%;
    height: 30px;
  }
.contact-grid-container{
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  /* background-color: #ffffff; */
  padding: 10px;
}
.postCodeSearchResult{
  position: absolute;
  padding-left: 0;
  z-index: 999;
  max-height: 15rem;
  overflow: scroll;
  border: 1px solid #e8f0fe;
}
.postCodeSearchResult::-webkit-scrollbar {
  display: none;
}
.postCodeSearchResult li {
  padding: 4px;
  line-height: 2;
  font-size: smaller;
  background: #fff;
  box-shadow: 1px 1px 2px 2px rgb(207 212 215 / 53%);
}
.postCodeSearchResult li:hover {
  background-color: #e9e9e9;
  cursor: pointer;
}
.postCodeSearchResultSite {
  max-height: 10rem;
}
.uploadPhotoButton {
  color: grey;
}
.Mui-selected {
  color: #05A6F0 !important;
}
.MuiPaper-root{
  margin: 1rem 0px !important;
  border-top: 0px !important;
  border: 0px solid #E2E8F0 !important;
  box-shadow: 0px 2px 5px #E2E8F0 !important;
  font-size: small !important;
}
.content, label, table {
  font-size: small !important;
}