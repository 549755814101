.header-nav>li.nav-item {
    color: var(--bs-nav-link-color) !important;
    font-size: large;
}

.header-nav .nav-link {
    color: var(--bs-nav-link-color) !important;
}

.grid-icon {
    color: grey;
    /* margin: 5px; */
}
.nav-icon{
    display: flex;
}
.icon{
    margin: 10px;
}
