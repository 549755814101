.fileSearchResult{
    position: absolute;
    padding-left: 0;
    z-index: 999;
    max-height: 15rem;
    overflow: scroll;
    border: 1px solid #e8f0fe;
  }
  .fileSearchResult::-webkit-scrollbar {
    display: none;
  }
  .fileSearchResult a {
    padding: 4px;
    line-height: 2;
    font-size: smaller;
    background: #fff;
    box-shadow: 1px 1px 2px 2px rgb(207 212 215 / 53%);
    display: flex;
  }
  .fileSearchResult a:hover {
    background-color: #e9e9e9;
    cursor: pointer;
  }
  .fileSearchResultSite {
    max-height: 10rem;
    width: 50% !important;
  }