.pagination-container {
    position: absolute;
    left: 4rem;
    margin-top: 6px;
}
@media only screen and (max-width: 600px) {
    .pagination-container{
        position: relative;
        left: 1rem;
        margin-top: 8px;
    }
  }