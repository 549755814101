thead th {
    font-size: inherit;
}

.f-11 {
    font-size: 11px;
}

.table-bordered> :not(caption)>*>* {
    padding: 0.5rem;
}
